html, body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: Tahoma;
    font-weight: bold;
    height: 100VH;
    position: relative;
    width: 100VW;
    user-select: none;
    -webkit-user-drag: none;
}
#maintenance{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgb(184, 44, 44);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#maintenance-text {
    font-size: large;
    position: relative;
    color: white;
    text-align: center;
    width: 500px;
}
#canvas {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
#overlays {
    width: 100vw;
    height: 100vh;
    padding: 5px 15px 5px 15px;
    position: relative;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}
#overlays::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    z-index: -1;
}
#UI {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 200;
    opacity: 1;
}
#UI::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(../static/img/hello-background.png);
    background-size: cover;
    background-position: top center;
    opacity: 1;
    z-index: -1;
}
.fade-out-zoom-in::before {
    animation: fadeOutZoomIn 1s forwards linear;
}
#logo {
    display: block;
    width: 14%;
	margin-bottom: 20px;
	margin-top: 50px;
    transition: all 2s ease;
	animation: hue-rotate-animation 12s infinite linear;
}
#logo-maintenance {
    position: absolute;
    display: block;
    width: 14%;
	margin-bottom: 500px;
    transition: all 2s ease;
	animation: hue-rotate-animation 12s infinite linear;
}
@media (max-width: 1366px) {
    #logo {
        display: block;
        width: 14%;
        margin-bottom: 20px;
        margin-top: 0px;
        transition: all 2s ease;
        animation: hue-rotate-animation 12s infinite linear;
    }
  }
#skin-container {
	display: flex;
    justify-content: center;
    align-items: flex-end;
}
@media (max-width: 1366px) {
    #skin-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        transform: scale(0.9);
    }
  }

#skin {
    width: 33%;
    float: right;
}
#skin-plus {
	width: 20px;
    height: auto;
	margin-bottom: -16px;
    border-color: #000000;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
}
#nick {
	width: 100%;
    text-align: center;
    border-radius: 13px;
    margin-top: 10px;
}
#buttons {
	display: flex;
	justify-content: center;
}
@media (max-width: 1366px) {
    #buttons {
        display: flex;
        justify-content: center;
        transform: scale(0.9);
    }
  }
#play-icon-left {
	width: auto;
    height: 20px;
    margin-right: auto;
    margin-top: 6px;
    margin-left: 6px;
}
#play-icon-right {
	width: auto;
    height: 20px;
    margin-left: auto;
    margin-right: 6px;
    margin-top: 6px;
}
#play-btn {
	width: 205px;
    background-image: linear-gradient(to bottom, #008BF8 70%, #0b7dd6 50%);
    -webkit-text-stroke: black;
    -webkit-text-stroke-width: 2px;
    color: white;
    font-family: 'Ubuntu';
    font-size: 26px;
    border-radius: 14px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
    justify-content: space-between;
    display: flex;
    letter-spacing: -1px;
}
#play-btn:hover {
    width: 205px;
    background-image: linear-gradient(to bottom, #56b5ff 70%, #45acfd 50%);
    -webkit-text-stroke: black;
    -webkit-text-stroke-width: 2px;
    color: white;
    font-family: 'Ubuntu';
    font-size: 26px;
    border-radius: 14px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
	justify-content: space-between;
    display: flex;
    letter-spacing: -1px;
}
#settings-icon {
	width: auto;
	height: 20px;
	margin-bottom: 3px;
}
#settings-btn {
    width: 50px;
    background-image: linear-gradient(to bottom, #635f55 70%, #514e42 50%);
    border-radius: 12px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
	margin-right: 5px;
}
#settings-btn:hover {
    width: 50px;
    background-image: linear-gradient(to bottom, #848483 70%, #6d6c68 50%);
    border-radius: 12px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
	margin-right: 5px;
}
#spectate-icon {
	width: auto;
    height: 27px;
    margin-bottom: -2px;
}
#spectate-btn {
    width: 50px;
    background-image: linear-gradient(to bottom, #F5B700 70%, #e2ac0e 50%);
    border-radius: 12px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
	margin-left: 5px;
}
#spectate-btn:hover {
    width: 50px;
    background-image: linear-gradient(to bottom, #ffce3d 70%, #efc444 50%);
    border-radius: 12px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
	margin-left: 5px;
}
#gallery-btn {
	width: 60px;
	height: 60px;
    background-image: linear-gradient(to bottom, #f20a0a 70%, #cf2d25 50%);
	font-family: 'Ubuntu';
	color: white;
	font-size: 22px;
	letter-spacing: -1px;
    -webkit-text-stroke: black;
    -webkit-text-stroke-width: 2px;
    border-radius: 57px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
}
#gallery-btn:hover {
    width: 60px;
	height: 60px;
    background-image: linear-gradient(to bottom, #fa6060 70%, #d75953 50%);
    font-family: 'Ubuntu';
	color: white;
    font-size: 22px;
	letter-spacing: -1px;
	-webkit-text-stroke: black;
    -webkit-text-stroke-width: 2px;
    border-radius: 57px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
}
#server-btns {
    position: relative;
    bottom: -40px;
}
@media (max-width: 1366px) {
    #server-btns {
        position: relative;
        transform: scale(0.9);
        bottom: 0px;
    }
  }
#ffa-btn, #teams-btn, #duels-btn, #experimental-btn {
	border-radius: 14px;
    height: 150px;
    width: 100px;
    cursor: pointer;
    transition-duration: 0.4s;
    border-style: solid;
    border-color: #000000;
    font-size: smaller;
    background-position: 0px 14px;
    background-size: 100% auto;
	padding-top: 80px;
	color: white;
	background-color: #70859a;
}
#ffa-btn {
	background-image: url(../static/img/ffa-bck.png);
}
#teams-btn {
	background-image: url(../static/img/teams-bck.png);
}
#duels-btn {
	background-image: url(../static/img/duels-bck.png);
}
#experimental-btn {
	background-image: url(../static/img/mega-bck.png);
}
#ffa-btn:hover {
    background-color: rgb(56 74 119);
    color: white;
}
#duels-btn:hover {
    background-color: rgb(160 60 60);
    color: white;
}
#teams-btn:hover {
    background-color: rgb(146 100 148);
    color: white;
}
#experimental-btn:hover {
    background-color: rgb(98 154 80);
    color: white;
}
input[type=checkbox] {
    cursor: pointer;
}
#connecting {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 500;
	background-color: rgba(0, 0, 0, 0.5);
}
#connecting-content {
    width: 350px;
    background-color: #fff;
    margin: 200px auto;
    border-radius: 15px;
    padding: 7px 17px 37px 12px;
	transform: scale(1);
	text-align: center;
	height: fit-content;
}
@media (max-width: 1366px) {
    #connecting-content {
        width: 350px;
        background-color: #fff;
        margin: 200px auto;
        border-radius: 15px;
        padding: 7px 17px 37px 12px;
        transform: scale(0.7);
        text-align: center;
        height: fit-content;
    }
  }
#close-button, #retry-button {
margin-top: 20px;
}
#chat_textbox {
    transition: all .5s ease-in-out;
    position: relative;
    z-index: 1;
    background: rgba(0, 0, 0, .2);
    border: 0px;
    outline: none;
    color: #fff;
    height: 28px;
    text-indent: 14px;
    width: 620px;
    font-family: "Ubuntu";
    border-top-right-radius: 8px;
	pointer-events: auto;
}
#chat_textbox:focus {
    background: rgba(0, 0, 0, .5);
}
#emoji-button {
    position: relative;
    z-index: 2;
    outline: none;
    border-radius: 12px;
    border: none;
    height: 28px;
    float: left;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
	pointer-events: auto;
}
#emojiPicker span {
    cursor: pointer;
    padding: 5px;
}
.emoji-picker {
    border-radius: 5px !important;
    background: #FFFFFF !important;
    height: 27.5rem !important;
    font-family: Arial, Helvetica, sans-serif !important;
    position: absolute !important;
    will-change: transform !important;
    transform: none !important;
    margin: 0px !important;
    border: none !important;
    z-index: 200 !important;
    width: fit-content !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    bottom: 9px !important;
    left: 43px !important;
    top: auto !important;
}
@media (max-width: 1366px) {
    .emoji-picker {
        transform: scale(0.7) !important;
        top: auto !important;
        bottom: -27px !important;
        left: 0px !important;
    }
  }
#footer {
    background-color: rgb(0 0 0 / 76%);
    height: 62px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 1366px) {
    #footer {
        background-color: rgb(0 0 0 / 76%);
        height: 62px;
        width: 100vw;
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  }
#footer-text {
    color: white;
    z-index: 200;
    text-align: center;
    line-height: 14px;
    margin-top: 10px;
}
#footer-title {
    font-size: 14px;
}
#termsOfUse {
    color: rgb(255, 255, 255);
    padding-right: 40px;
    margin-top: 16px;
  }
#privacyPolicy {
    padding-left: 40px;
    color: rgb(255, 255, 255);
    margin-top: 16px;
  }
#privacyPolicy:hover, #termsOfUse:hover {
    color: #00ceff;;
  }
#loginContainer {
	margin-top: 6px;
	width: 100%;
	font-weight: bold;
	display: none;
}
#profilePanelTop {
    width: 240px;
    height: 180px;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: -243px;
    z-index: 2;
	display: none;
}
#profileText {
    margin-left: 10px;
    margin-top: 24px;
    font-size: 11px;
}
#GuestprofileText {
    margin-left: 10px;
    margin-top: 40px;
    font-size: 11px;
    text-align: center
}
#userProfilePic {
    width: 70px;
    height: 70px;
    border-radius: 10%;
    margin-left: 14px;
    margin-top: 14px;
}
#guestProfilePic {
    width: 70px;
    height: 70px;
    border-radius: 10%;
    margin-left: 14px;
    margin-top: 14px;
}
#ProfileUsername {
    font-size: xx-large;
    margin-left: 97px;
    margin-top: -72px;
}
#guestUsername {
    font-size: xx-large;
    margin-left: 97px;
    margin-top: -55px; 
}
#expStats {
    margin-left: 100px;
    margin-top: -20px;
}
#levelBar {
    width: 142px;
    height: 20px;
    background-color: #00b3ff;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 90px;
}
#progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: black;
  border-radius: 5px;
  transition: width 0.5s ease;
  width: 10%;
  border-color: grey;
}
#levelIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
#levelIndicator img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#galleryNewIcon {
    width: auto;
    height: 20px;
    float: left;
    animation: resizeAndRotate 2s ease-in-out infinite; 
	position: absolute;
	left: 30px;
}
#touchpad, #touchCircle, #splitBtn, #ejectBtn {
    position: fixed;
}
#touchpad, #splitBtn, #ejectBtn {
    z-index: 2;
}
#touchpad {
    background: #000;
    opacity: 0.3;
    width: 20vw;
    height: 20vh;
    left: 0;
    bottom: 0;
}
#touchCircle {
    width: 4vw;
    height: 4vw;
    border-radius: 2vw;
    background: #f00;
    opacity: 0.3;
    z-index: 3;
}
#splitBtn, #ejectBtn {
    width: 8vw;
    height: 8vw;
    border-radius: 4vw;
    background: #777;
    opacity: 0.5;
}
#splitBtn {
    right: 18vw;
    bottom: 0;
}
#ejectBtn {
    bottom: 18vw;
    right: 0;
}
#playSounds:checked + #soundsVolume {
    display: inline;
}
#soundsVolume {
    display: none;
    width: 100px;
    height: 18px;
    padding: 0; /* IE fix */
    vertical-align: middle;
}
#label {
  margin-left: 10px;
}
#discord-banner {
    position: relative;
    top: 80px;
}
#discord-banner:hover {
    position: relative;
    top: 80px;
    filter: hue-rotate(335deg);
}
#discordButton{
    border-radius: 10px;
}
@media (max-width: 1366px) {
    #discord-banner {
        position: relative;
        top: 0px;
        transform: scale(0.7);
    }
    #discord-banner:hover {
        position: relative;
        top: 0px;
        transform: scale(0.7);
        filter: hue-rotate(324deg);
    }
  }
  #donation-button {
    margin-top: 100px;
  }

/*Classes ----------------------------------------------------------------*/

.loader {
    border: 6px solid #53513d;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 0.7s linear infinite;
    left: 133px;
    top: 10px;
    position: relative;
	margin-top: 10px;
	margin-bottom: 20px;
}
.scaled-down {
    transform: scale(1);
}
.checkbox {
    height: 170px;
    display: flex;
    flex-flow: column wrap;
    align-content: space-evenly;
    gap: 5px 6px;
    padding-top: 3px;
}
.form-group {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
@media (max-width: 1366px) {
    .form-group {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transform: scale(0.8);
        margin-bottom: 0px;
    }
  }
.custom-recaptcha-badge {
	background-color: #f4f4f4;
    border: 1px solid #e0e0e0;
    padding: 5px 10px;
    font-size: 12px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-right-radius: 21px;
    display: block;
}
@media (max-width: 1366px) {
    .custom-recaptcha-badge {
        display: block;
        transform: scale(0.7);
        transform-origin: top left;
    }
  }


.btn-info {
	color: #fff;
    background-color: rgb(0, 179, 255);
    border-color: #46b8da;
}
.custom-recaptcha-badge img {
    height: 20px;
    margin-right: 5px;
}
.custom-recaptcha-badge span {
    font-family: Arial, sans-serif;
}
.grecaptcha-badge { 
    visibility: hidden;
}
.fadeOut {
    animation: fadeOut 0.2s linear forwards;
}
.fadeIn {
    animation: fadeIn 0.5s linear forwards;
}

/*Animations -------------------------------------------------------------*/

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes fadeIn {
    from {
      opacity: 0;
    } to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    } to {
      opacity: 0;
    }
  }
@keyframes fadeOutZoomIn {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(3);
    }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes resizeAndRotate {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.5) rotate(12deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}
@keyframes hue-rotate-animation {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}
@keyframes scaleDecrease {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
}
@keyframes fall {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(110vh); }
}
@keyframes flashRed {
    0%, 100% { 
        background-color: initial; 
        transform: scale(1); 
        box-shadow: none; 
        border-color: transparent; 
    }
    25%, 75% { 
        background-color: #ffcccc; 
        transform: scale(1.05); 
        box-shadow: 0 0 8px red; 
        border-color: red; 
    }
}
#pieChartCanvas {
    width: 200px;
    height: 240px;
    margin-bottom: -40px;
}
#chatCont {
    width: fit-content;
    height: fit-content;
    z-index: 20;
    position: absolute;
    bottom: 10px;
    left: 10px;
    pointer-events: none;
}
@media (max-width: 1366px) {
    #chatCont {
        transform: scale(0.7);
        bottom: 0px;
        left: -80px;
    }
  }
  .flash-red {
    animation: flashRed 1s ease-in-out;
}
.chat-container {
    position: relative;
    bottom: 5px;
    max-width: 600px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    line-height: 17px;
    user-select: none;
    left: 20px;
}
.chat-container::-webkit-scrollbar {
    display: none;
}
.chat-message {
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    margin-bottom: 5px;
}
.chat-scrollbar {
    position: absolute;
    bottom: 37px;
    width: 10px;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    pointer-events: auto;
}

.chat-scrollbar-thumb {
    position: relative;
    width: 100%;
    background: rgba(255, 255, 255, 0.5); /* Scrollbar thumb */
    cursor: pointer;
    border-radius: 5px; /* Optional: for rounded corners */
}
.leaderboard {
    display: flex;
    position: fixed;
    width: 220px;
    justify-content: center;
    flex-direction: column;
    background: rgba(0,0,0,.3);
    overflow: hidden;
    padding: 10px;
    right: 5px;
    top: 5px;
    transform-origin: top right;
    z-index: 1;
    transform: scale(1);
    font-size: 30px;
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    user-select: none;
	pointer-events: none;
}
.leaderboard .positions {
    text-align: left;
    font-size: 18px;
}
@media (max-width: 1366px) {
    .leaderboard {
        transform: scale(0.7);
    }
  }
  .stats-container {
    font-family: Ubuntu, sans-serif;
    font-size: 16px;
    color: grey;
    display: none;
    position: absolute;
    z-index: 1;
    flex-direction: column;
    top: 15px;
    left: 19px;
    line-height: 20px;
    user-select: none;
	pointer-events: none;
}
@media (max-width: 1366px) {
    .stats-container {
        transform: scale(0.7);
        top: -20px;
        left: -20px;
    }
  }
#scoreStat {
    font-size: 30px;
    color: white;
}
#fpsPingStat {
    font-size: large;
    color: white;
    margin-top: 6px;
}
#serverNameMode, #playerCount, #playersPlaying, #playersSpectating, #serverLoad {
    font-size: 16px;
}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2a3141;
    display: flex;
    justify-content: center;
    align-items: center;
	z-index: 600;
	gap: 10px;
	transition: transform 2s ease-in-out;
	overflow: hidden
}
.dot {
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
	animation: scaleDecrease 1.2s forwards;
}
#tick {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 12px; /* Adjust size as needed */
    height: 6px; /* Adjust size as needed */
    border: solid white;
    border-width: 0 3px 3px 0;
	transform: scale(4, 4) translate(10%, -400%) rotate(315deg) scaleX(-1);
    display: none;
	z-index: 300;
	animation: fadeIn 2s forwards;
}
.dot:nth-child(1) { animation-delay: -0.32s; }
.dot:nth-child(2) { animation-delay: -0.16s; }

.slide-down {
    transform: translateY(100%); /* Slide down */
}

.snowflake-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 300; /* Ensure it's above other content */
	pointer-events: none;
}

.snowflake {
    position: absolute;
    top: -10%;
    font-size: 1em;
    color: white;
    opacity: 1;
    animation-name: fall;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.hidden {
    display: none;
}