html, body {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Tahoma;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

#maintenance {
  z-index: 10000;
  background-color: #b82c2c;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

#maintenance-text {
  color: #fff;
  text-align: center;
  width: 500px;
  font-size: large;
  position: relative;
}

#canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

#overlays {
  z-index: 200;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 5px 15px;
  display: flex;
  position: relative;
}

#overlays:before {
  content: "";
  opacity: 1;
  z-index: -1;
  position: absolute;
  inset: 0;
}

#UI {
  z-index: 200;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

#UI:before {
  content: "";
  opacity: 1;
  z-index: -1;
  background-image: url("hello-background.4785e6c7.png");
  background-position: top;
  background-size: cover;
  position: absolute;
  inset: 0;
}

.fade-out-zoom-in:before {
  animation: 1s linear forwards fadeOutZoomIn;
}

#logo {
  width: 14%;
  margin-top: 50px;
  margin-bottom: 20px;
  transition: all 2s;
  animation: 12s linear infinite hue-rotate-animation;
  display: block;
}

#logo-maintenance {
  width: 14%;
  margin-bottom: 500px;
  transition: all 2s;
  animation: 12s linear infinite hue-rotate-animation;
  display: block;
  position: absolute;
}

@media (width <= 1366px) {
  #logo {
    width: 14%;
    margin-top: 0;
    margin-bottom: 20px;
    transition: all 2s;
    animation: 12s linear infinite hue-rotate-animation;
    display: block;
  }
}

#skin-container {
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

@media (width <= 1366px) {
  #skin-container {
    justify-content: center;
    align-items: flex-end;
    display: flex;
    transform: scale(.9);
  }
}

#skin {
  float: right;
  width: 33%;
}

#skin-plus {
  border: 2px solid #000;
  border-radius: 50%;
  width: 20px;
  height: auto;
  margin-bottom: -16px;
}

#nick {
  text-align: center;
  border-radius: 13px;
  width: 100%;
  margin-top: 10px;
}

#buttons {
  justify-content: center;
  display: flex;
}

@media (width <= 1366px) {
  #buttons {
    justify-content: center;
    display: flex;
    transform: scale(.9);
  }
}

#play-icon-left {
  width: auto;
  height: 20px;
  margin-top: 6px;
  margin-left: 6px;
  margin-right: auto;
}

#play-icon-right {
  width: auto;
  height: 20px;
  margin-top: 6px;
  margin-left: auto;
  margin-right: 6px;
}

#play-btn {
  -webkit-text-stroke: black;
  -webkit-text-stroke-width: 2px;
  color: #fff;
  letter-spacing: -1px;
  background-image: linear-gradient(#008bf8 70%, #0b7dd6 50%);
  border: 3px solid #000;
  border-radius: 14px;
  justify-content: space-between;
  width: 205px;
  font-family: Ubuntu;
  font-size: 26px;
  display: flex;
}

#play-btn:hover {
  -webkit-text-stroke: black;
  -webkit-text-stroke-width: 2px;
  color: #fff;
  letter-spacing: -1px;
  background-image: linear-gradient(#56b5ff 70%, #45acfd 50%);
  border: 3px solid #000;
  border-radius: 14px;
  justify-content: space-between;
  width: 205px;
  font-family: Ubuntu;
  font-size: 26px;
  display: flex;
}

#settings-icon {
  width: auto;
  height: 20px;
  margin-bottom: 3px;
}

#settings-btn {
  background-image: linear-gradient(#635f55 70%, #514e42 50%);
  border: 3px solid #000;
  border-radius: 12px;
  width: 50px;
  margin-right: 5px;
}

#settings-btn:hover {
  background-image: linear-gradient(#848483 70%, #6d6c68 50%);
  border: 3px solid #000;
  border-radius: 12px;
  width: 50px;
  margin-right: 5px;
}

#spectate-icon {
  width: auto;
  height: 27px;
  margin-bottom: -2px;
}

#spectate-btn {
  background-image: linear-gradient(#f5b700 70%, #e2ac0e 50%);
  border: 3px solid #000;
  border-radius: 12px;
  width: 50px;
  margin-left: 5px;
}

#spectate-btn:hover {
  background-image: linear-gradient(#ffce3d 70%, #efc444 50%);
  border: 3px solid #000;
  border-radius: 12px;
  width: 50px;
  margin-left: 5px;
}

#gallery-btn {
  color: #fff;
  letter-spacing: -1px;
  -webkit-text-stroke: black;
  -webkit-text-stroke-width: 2px;
  background-image: linear-gradient(#f20a0a 70%, #cf2d25 50%);
  border: 3px solid #000;
  border-radius: 57px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-family: Ubuntu;
  font-size: 22px;
  display: flex;
}

#gallery-btn:hover {
  color: #fff;
  letter-spacing: -1px;
  -webkit-text-stroke: black;
  -webkit-text-stroke-width: 2px;
  background-image: linear-gradient(#fa6060 70%, #d75953 50%);
  border: 3px solid #000;
  border-radius: 57px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-family: Ubuntu;
  font-size: 22px;
  display: flex;
}

#server-btns {
  position: relative;
  bottom: -40px;
}

@media (width <= 1366px) {
  #server-btns {
    position: relative;
    bottom: 0;
    transform: scale(.9);
  }
}

#ffa-btn, #teams-btn, #duels-btn, #experimental-btn {
  cursor: pointer;
  color: #fff;
  background-color: #70859a;
  background-position: 0 14px;
  background-size: 100%;
  border-style: solid;
  border-color: #000;
  border-radius: 14px;
  width: 100px;
  height: 150px;
  padding-top: 80px;
  font-size: smaller;
  transition-duration: .4s;
}

#ffa-btn {
  background-image: url("ffa-bck.434ec686.png");
}

#teams-btn {
  background-image: url("teams-bck.c714dd8a.png");
}

#duels-btn {
  background-image: url("duels-bck.4b6aa4e5.png");
}

#experimental-btn {
  background-image: url("mega-bck.44985634.png");
}

#ffa-btn:hover {
  color: #fff;
  background-color: #384a77;
}

#duels-btn:hover {
  color: #fff;
  background-color: #a03c3c;
}

#teams-btn:hover {
  color: #fff;
  background-color: #926494;
}

#experimental-btn:hover {
  color: #fff;
  background-color: #629a50;
}

input[type="checkbox"] {
  cursor: pointer;
}

#connecting {
  z-index: 500;
  background-color: #00000080;
  position: absolute;
  inset: 0;
}

#connecting-content {
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  width: 350px;
  height: fit-content;
  margin: 200px auto;
  padding: 7px 17px 37px 12px;
  transform: scale(1);
}

@media (width <= 1366px) {
  #connecting-content {
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    width: 350px;
    height: fit-content;
    margin: 200px auto;
    padding: 7px 17px 37px 12px;
    transform: scale(.7);
  }
}

#close-button, #retry-button {
  margin-top: 20px;
}

#chat_textbox {
  z-index: 1;
  color: #fff;
  text-indent: 14px;
  pointer-events: auto;
  background: #0003;
  border: 0;
  border-top-right-radius: 8px;
  outline: none;
  width: 620px;
  height: 28px;
  font-family: Ubuntu;
  transition: all .5s ease-in-out;
  position: relative;
}

#chat_textbox:focus {
  background: #00000080;
}

#emoji-button {
  z-index: 2;
  float: left;
  pointer-events: auto;
  border: none;
  border-radius: 12px 0 0 12px;
  outline: none;
  height: 28px;
  position: relative;
}

#emojiPicker span {
  cursor: pointer;
  padding: 5px;
}

.emoji-picker {
  will-change: transform !important;
  z-index: 200 !important;
  background: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  flex-direction: column-reverse !important;
  width: fit-content !important;
  height: 27.5rem !important;
  margin: 0 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  display: flex !important;
  position: absolute !important;
  top: auto !important;
  bottom: 9px !important;
  left: 43px !important;
  transform: none !important;
}

@media (width <= 1366px) {
  .emoji-picker {
    top: auto !important;
    bottom: -27px !important;
    left: 0 !important;
    transform: scale(.7) !important;
  }
}

#footer {
  background-color: #000000c2;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 62px;
  font-size: 12px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (width <= 1366px) {
  #footer {
    background-color: #000000c2;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 62px;
    font-size: 12px;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

#footer-text {
  color: #fff;
  z-index: 200;
  text-align: center;
  margin-top: 10px;
  line-height: 14px;
}

#footer-title {
  font-size: 14px;
}

#termsOfUse {
  color: #fff;
  margin-top: 16px;
  padding-right: 40px;
}

#privacyPolicy {
  color: #fff;
  margin-top: 16px;
  padding-left: 40px;
}

#privacyPolicy:hover, #termsOfUse:hover {
  color: #00ceff;
}

#loginContainer {
  width: 100%;
  margin-top: 6px;
  font-weight: bold;
  display: none;
}

#profilePanelTop {
  z-index: 2;
  background-color: #fff;
  border-radius: 15px;
  width: 240px;
  height: 180px;
  display: none;
  position: absolute;
  top: 0;
  left: -243px;
}

#profileText {
  margin-top: 24px;
  margin-left: 10px;
  font-size: 11px;
}

#GuestprofileText {
  text-align: center;
  margin-top: 40px;
  margin-left: 10px;
  font-size: 11px;
}

#userProfilePic, #guestProfilePic {
  border-radius: 10%;
  width: 70px;
  height: 70px;
  margin-top: 14px;
  margin-left: 14px;
}

#ProfileUsername {
  margin-top: -72px;
  margin-left: 97px;
  font-size: xx-large;
}

#guestUsername {
  margin-top: -55px;
  margin-left: 97px;
  font-size: xx-large;
}

#expStats {
  margin-top: -20px;
  margin-left: 100px;
}

#levelBar {
  background-color: #00b3ff;
  border-radius: 10px;
  width: 142px;
  height: 20px;
  margin-left: 90px;
  overflow: hidden;
}

#progress {
  background-color: #000;
  border-color: gray;
  border-radius: 5px;
  width: 10%;
  height: 100%;
  transition: width .5s;
  position: absolute;
  top: 0;
  left: 0;
}

#levelIndicator {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#levelIndicator img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

#galleryNewIcon {
  float: left;
  width: auto;
  height: 20px;
  animation: 2s ease-in-out infinite resizeAndRotate;
  position: absolute;
  left: 30px;
}

#touchpad, #touchCircle, #splitBtn, #ejectBtn {
  position: fixed;
}

#touchpad, #splitBtn, #ejectBtn {
  z-index: 2;
}

#touchpad {
  opacity: .3;
  background: #000;
  width: 20vw;
  height: 20vh;
  bottom: 0;
  left: 0;
}

#touchCircle {
  opacity: .3;
  z-index: 3;
  background: red;
  border-radius: 2vw;
  width: 4vw;
  height: 4vw;
}

#splitBtn, #ejectBtn {
  opacity: .5;
  background: #777;
  border-radius: 4vw;
  width: 8vw;
  height: 8vw;
}

#splitBtn {
  bottom: 0;
  right: 18vw;
}

#ejectBtn {
  bottom: 18vw;
  right: 0;
}

#playSounds:checked + #soundsVolume {
  display: inline;
}

#soundsVolume {
  vertical-align: middle;
  width: 100px;
  height: 18px;
  padding: 0;
  display: none;
}

#label {
  margin-left: 10px;
}

#discord-banner {
  position: relative;
  top: 80px;
}

#discord-banner:hover {
  filter: hue-rotate(335deg);
  position: relative;
  top: 80px;
}

#discordButton {
  border-radius: 10px;
}

@media (width <= 1366px) {
  #discord-banner {
    position: relative;
    top: 0;
    transform: scale(.7);
  }

  #discord-banner:hover {
    filter: hue-rotate(324deg);
    position: relative;
    top: 0;
    transform: scale(.7);
  }
}

#donation-button {
  margin-top: 100px;
}

.loader {
  border: 6px solid #53513d;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 20px;
  animation: .7s linear infinite spin;
  position: relative;
  top: 10px;
  left: 133px;
}

.scaled-down {
  transform: scale(1);
}

.checkbox {
  flex-flow: column wrap;
  align-content: space-evenly;
  gap: 5px 6px;
  height: 170px;
  padding-top: 3px;
  display: flex;
}

.form-group {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (width <= 1366px) {
  .form-group {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
    transform: scale(.8);
  }
}

.custom-recaptcha-badge {
  background-color: #f4f4f4;
  border: 1px solid #e0e0e0;
  border-bottom-right-radius: 21px;
  width: 200px;
  padding: 5px 10px;
  font-size: 12px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 5px #0000001a;
}

@media (width <= 1366px) {
  .custom-recaptcha-badge {
    transform-origin: 0 0;
    display: block;
    transform: scale(.7);
  }
}

.btn-info {
  color: #fff;
  background-color: #00b3ff;
  border-color: #46b8da;
}

.custom-recaptcha-badge img {
  height: 20px;
  margin-right: 5px;
}

.custom-recaptcha-badge span {
  font-family: Arial, sans-serif;
}

.grecaptcha-badge {
  visibility: hidden;
}

.fadeOut {
  animation: .2s linear forwards fadeOut;
}

.fadeIn {
  animation: .5s linear forwards fadeIn;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutZoomIn {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(3);
  }
}

@keyframes resizeAndRotate {
  0% {
    transform: scale(1)rotate(0);
  }

  50% {
    transform: scale(1.5)rotate(12deg);
  }

  100% {
    transform: scale(1)rotate(0);
  }
}

@keyframes hue-rotate-animation {
  0% {
    filter: hue-rotate();
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes scaleDecrease {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

@keyframes fall {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(110vh);
  }
}

@keyframes flashRed {
  0%, 100% {
    background-color: initial;
    box-shadow: none;
    border-color: #0000;
    transform: scale(1);
  }

  25%, 75% {
    background-color: #fcc;
    border-color: red;
    transform: scale(1.05);
    box-shadow: 0 0 8px red;
  }
}

#pieChartCanvas {
  width: 200px;
  height: 240px;
  margin-bottom: -40px;
}

#chatCont {
  z-index: 20;
  pointer-events: none;
  width: fit-content;
  height: fit-content;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

@media (width <= 1366px) {
  #chatCont {
    bottom: 0;
    left: -80px;
    transform: scale(.7);
  }
}

.flash-red {
  animation: 1s ease-in-out flashRed;
}

.chat-container {
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  max-width: 600px;
  max-height: 350px;
  line-height: 17px;
  position: relative;
  bottom: 5px;
  left: 20px;
  overflow: hidden auto;
}

.chat-container::-webkit-scrollbar {
  display: none;
}

.chat-message {
  margin-bottom: 5px;
  font-family: Ubuntu, sans-serif;
  font-size: 18px;
}

.chat-scrollbar {
  pointer-events: auto;
  background: #fff3;
  width: 10px;
  height: 100%;
  position: absolute;
  bottom: 37px;
}

.chat-scrollbar-thumb {
  cursor: pointer;
  background: #ffffff80;
  border-radius: 5px;
  width: 100%;
  position: relative;
}

.leaderboard {
  transform-origin: 100% 0;
  z-index: 1;
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background: #0000004d;
  flex-direction: column;
  justify-content: center;
  width: 220px;
  padding: 10px;
  font-size: 30px;
  font-weight: 500;
  display: flex;
  position: fixed;
  top: 5px;
  right: 5px;
  overflow: hidden;
  transform: scale(1);
}

.leaderboard .positions {
  text-align: left;
  font-size: 18px;
}

@media (width <= 1366px) {
  .leaderboard {
    transform: scale(.7);
  }
}

.stats-container {
  color: gray;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  flex-direction: column;
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: none;
  position: absolute;
  top: 15px;
  left: 19px;
}

@media (width <= 1366px) {
  .stats-container {
    top: -20px;
    left: -20px;
    transform: scale(.7);
  }
}

#scoreStat {
  color: #fff;
  font-size: 30px;
}

#fpsPingStat {
  color: #fff;
  margin-top: 6px;
  font-size: large;
}

#serverNameMode, #playerCount, #playersPlaying, #playersSpectating, #serverLoad {
  font-size: 16px;
}

#preloader {
  z-index: 600;
  background-color: #2a3141;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  transition: transform 2s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.dot {
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: 1.2s forwards scaleDecrease;
  display: inline-block;
}

#tick {
  content: "";
  z-index: 300;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  width: 12px;
  height: 6px;
  animation: 2s forwards fadeIn;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(4)translate(10%, -400%)rotate(315deg)scaleX(-1);
}

.dot:first-child {
  animation-delay: -.32s;
}

.dot:nth-child(2) {
  animation-delay: -.16s;
}

.slide-down {
  transform: translateY(100%);
}

.snowflake-container {
  z-index: 300;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.snowflake {
  color: #fff;
  opacity: 1;
  font-size: 1em;
  animation-name: fall;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: -10%;
}

.hidden {
  display: none;
}
/*# sourceMappingURL=index.f7068c56.css.map */
